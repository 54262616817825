@import '../../../../scss/theme-bootstrap';

.supercat_product_layout {
  position: relative;
  max-width: $max-width-xlarge;
  margin: 0 auto;
  &.padding-default-wrapper {
    padding: 30px 0;
    @include breakpoint($medium-up) {
      padding: 40px 20px;
    }
  }
  // small-down neccessary because of conflict with padding rules
  @include breakpoint($small-down) {
    &.mobile-top-border {
      border-top: 1px solid $color-gray-op10;
      padding-top: 40px;
    }
  }
  @include breakpoint($large-up) {
    padding-#{$ldirection}: 20px;
    padding-#{$rdirection}: 20px;
  }
  &__anchor {
    display: block;
    height: 40px;
    margin-top: -40px;
    visibility: hidden;
  }
  &__header {
    z-index: 100;
    padding-#{$rdirection}: 80px;
    padding-#{$ldirection}: 20px;
    @include breakpoint($large-up) {
      top: 100px;
      width: 25%;
      padding-#{$rdirection}: 5%;
      &-content {
        padding-bottom: 100px;
      }
    }
    &.sticky-header {
      @include breakpoint($large-up) {
        position: sticky;
      }
    }
    .content-block {
      &__line--header {
        .style--bare {
          @include typography-headline--m;
          color: $color-core-black;
          margin-#{$ldirection}: 0;
        }
      }
      &__line--content {
        .style--bare {
          @include typography-body-text;
          color: $color-gray-op65;
        }
      }
      &__buttons {
        margin: 1rem auto;
      }
      &__content-button {
        @include typography-body-text;
        color: $color-core-black;
      }
    }
    .content-block__link--bottom {
      .content-block__line {
        .button {
          width: 100%;
        }
      }
    }
  }
  &__grid {
    @include breakpoint($large-up) {
      margin-#{$ldirection}: 25%;
    }
  }
  &__picture {
    position: absolute;
    #{$ldirection}: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .product-grid__wrapper {
    margin: 0;
  }
  .product-grid__item {
    width: 100%;
    margin-bottom: 0;
    padding: 20px;
    margin: 0;
    @include breakpoint($medium-up) {
      width: 50%;
      padding: 0 20px 60px;
      &:nth-child(2n) {
        border-#{$ldirection}: 1px solid $color-gray-op15;
      }
    }
    @include breakpoint($large-up) {
      width: calc(100% / 3);
      border-#{$ldirection}: 1px solid $color-gray-op15;
    }
  }
  &.dark-background {
    .product-grid__item {
      border-color: $color-white-op15;
    }
    .product-grid--carousel {
      .product-grid__item,
      .product-grid__item:first-child {
        border-color: $color-white-op15;
      }
    }
  }
  .product-grid--carousel {
    .product-grid__item {
      @include breakpoint($medium-up) {
        border-#{$ldirection}: 1px solid $color-gray-op15;
        &:first-child {
          border: none;
        }
      }
      @include breakpoint($large-up) {
        &:first-child {
          border-#{$ldirection}: 1px solid $color-gray-op15;
        }
      }
    }
  }
  .hide-grid-lines {
    .product-grid__item {
      @include breakpoint($medium-up) {
        &:nth-child(2n) {
          border-#{$ldirection}: 0;
        }
      }
      @include breakpoint($large-up) {
        border-#{$ldirection}: 0;
      }
    }
    .product-grid--carousel {
      .product-grid__item {
        border-#{$ldirection}: 0;
      }
    }
  }
  .supercat_product_layout__header {
    top: 200px;
    .site-header-formatter--hidden & {
      top: 100px;
    }
  }
}
